.contact-card {
  i {
      color: $col-main;
      margin-right: 0.5rem;
  }
  a {
      display: block;
      font-size: 1rem;
      color: $col-text;
  }
  .row {
      margin-left:  0;
      margin-right: 0;
  }
}