/* Navbar
----------------------------------------------------------------------------*/

/* Add a black background color to the top navigation */
#main-nav {
  z-index: 100;
  background-color: $col-bg;
  padding: 0;
  /* Style the links inside the navigation bar */
  a {
    color: $col-main;
    /* Change the color of links on hover */
    &:hover {
      color: $col-hover;
    }
    /* Add an active class to highlight the current page */
    &.active {
      background-color: $col-active;
    }
    img {
      width: 24px;
      height: 15px;
      margin: auto 5px auto;
    }
  }

  .navbar-brand, .nav-item {
    padding-left: 8px;
  }
  @include break(768px) {
    .navbar-brand, .nav-item {
      padding-left: auto;
    }
  }
}