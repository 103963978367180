#about {
    @include break(768px) {
        img {
            max-width:  40vw;
            max-height: 40vh;
            margin-right: 8px;
        }
    }
    a, i {
        color: $col-head;
        &:hover { color: $col-main; }
    }
    i {
        margin-right: 8px;
    }
}