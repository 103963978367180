// Load Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Montserrat&family=Roboto&display=swap');
$font-main: 'Nunito', Helvetica, sans-serif;
$font-highlight: 'Montserrat', Helvetica, sans-serif;

// Colors palette 1
$color11: #848c8e;
$color12: #f9dc5c;
$color13: #435058;
$color14: #397367;
$color15: #bfb7b6;
// Colors palette 2
$color21: #575c55;
$color22: #ffa400;
$color23: #45425a;
$color24: #99c24d;
$color25: #048ba8;

// Heights
$height-navbar: 40px;
$height-footer: 35px;

// Banner
$image-banner: "../images/banner.jpg";

$col-main:   $color12;
$col-text:   $color13;
$col-head:   $color11;
$col-bg:     $color13;
$col-bg2:    $color15;
$col-hover:  $color14;
$col-active: $color11;

$col-resume-1: white;
$col-resume-2: $color12;

// Breakpoints
$sm: 450px;
$md: 760px;
$lg: 1200px;
