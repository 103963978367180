#projects {
    #intro {
        background-color: $col-bg2;
    }
    #references {
        h4, h5 {
            color: $col-main;
        }
        h4 {
            font-size: 1.4rem;
            @include break(768px) {
                font-size: 1.5rem;
            }
            font-weight: bold;
        }
        h5 {
            font-size: 1.2rem;
            font-style: italic;
        }
        .col {
            padding: 4px;
        }
        .card {
            border-color: $col-main;
        }
        .row {
            margin-left:  0;
            margin-right: 0;
        }
    }
}