#home {
    #catch {
        background-image: url('../images/main.jpg');
        background-position: top left;
        background-size: cover;
        width: 100%;
        height: calc(100vh - #{$height-navbar} - #{$height-footer});

        .img-text {
            padding-top: calc(0.35 * (100vh - #{$height-navbar} - #{$height-footer}));

            h1, h4 {
                color: $col-main;
            }
            h4 {
                margin: 0;
                font-style: italic;
                font-size: 0.9rem;
                @include break(768px) {
                    font-size: 2rem;
                }
            }
            h1 {
                font-weight: bold;
                font-size: 2.7rem;
                margin: 0 auto 0;
                @include break(768px) {
                    font-size: 4rem;
                }
            }
        }
    }

    #goal {
        h1 {
            font-size: 2.7rem;
            @include break(768px) {
                font-size: 4rem;
            }
        }
        h4, h5 {
            color: $col-main;
        }
        h4 {
            font-size: 1.5rem;
            font-weight: bold;
        }
        h5 {
            font-size: 1.2rem;
            font-style: italic;
        }
        .col {
            padding: 4px;
        }
        .card {
            border-color: $col-main;
        }
        .row {
            margin-left:  0;
            margin-right: 0;
        }
    }

    #contact {
        background-color: $col-bg2;
    }
}