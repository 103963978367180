/* Banner
----------------------------------------------------------------------------*/

header {
  background-image: url($image-banner);
  background-color: $col-bg;
  margin: 0;
  h1 {
    font-size: 3rem;
    margin: 0;
    @include break(768px) {
      font-size: 4rem;
    }
    @media print {
      font-size: 4rem;
    }
  }
  h2 {
    font-size: 1rem;
    @include break(768px) {
      font-size: 1.5rem;
    }
    @media print {
      font-size: 1.5rem;
    }
  }
  h1, h2 {
    color: $col-main;
  }
}

.main-picture {
  background-color: $col-bg;
  margin: 5px;
  max-height: 200px;
  @media print {
    max-height: 210px;
    margin: 10px;
  }
}