/* Resume styles
----------------------------------------------------------------------------*/

#resume {
    @include break(768px) {
        display: flex;
    }
    @media print {
        display: flex;
    }
    .left {
        @include break(768px) {
            width: 28%;
        }
        @media print {
            width: 28%;
        }
        background-color: $col-resume-1;
        padding: 8px 10px 0;
    }
    .right {
        @include break(768px) {
            width: 72%;
        }
        @media print {
            width: 72%;
        }
        background-color: $col-resume-2;
        padding: 8px 10px 0;
    }
    
    h3 {
        color: $col-text;
        font-size: 1.3rem;
        text-transform: uppercase;
        &:after {
            content:' ';
            display: block;
            border: 2px solid $col-text;
            width: 50px;
            margin-top: 10px;
        }
    }

    section p {
        text-align: justify;
        font-size: small;
    }

    /* Contacts ----- */
    .d-flex {
        i {
            color: $col-main;
        }
        a {
            font-size: small;
            color: $col-text;
            @media print {
                text-decoration: none;
            }
        }
    }

    /* Languages ----- */

    .percentage {
        padding: 0;
        margin: 0;
        height: 20px;
    }
    
    .language-flags {
        img {
            width: 16px;
            height: 10px;
            margin-right: 5px;
        }
        span {
            display: inline-block;
            font-size: small;
            width: 50px;
        }
        .progress {
            width: 150px;
            height: 16px;
            
            .progress-bar {
                background-color: $col-main;
            }
        }
    }

    /* Skills ----- */

    .skills {
        span {
            display: inline-block;
            font-size: small;
            width: 71px; /* img 16 + margin 5 + span 50 -> 71px*/
        }
        .progress {
            width: 150px;
            height: 16px;
            
            .progress-bar {
                background-color: $col-main;
            }
        }
    }

    /* Experiences/Education ----- */

    #experiences, #educations {
        p {
            margin: 0 auto 0;
        }
        .technology {
            font-weight: bold;
            font-style: italic;
            padding-left: 5px;
        }
        .group-description {
            padding-left: 10px;
            border-left: 2px solid $col-text;
        }
        h4 {
            color: $col-text;
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 0;
        }
        .shift {
            display: inline-block;
            width: 140px;
            font-style: italic;
            margin-bottom: 0;
        }
        .shift, h5 {
            font-size: 1.0rem;
        }

        .award {
            margin: 0;
            font-weight: bold;
            color: $col-text;
        }

        .role-title {
            color: $col-text;
            font-weight: bold;
        }
    }

    .role:last-of-type {
        .group-description:last-of-type {
            margin-bottom: 0 !important;
        }
    }
}
