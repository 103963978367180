/* Footer
----------------------------------------------------------------------------*/

#footer {
  background-color: $col-bg;
  margin: 0;
  padding: 0;
  width: 100%;
  h1, h2, h3, h4, p {
    color: $col-head;
    margin: 0;
  }
  h4 {
    font-size: large;
  }
  i {
    margin-right: 8px;
    &:hover {
      color: $col-main;
    }
  }
}

.footer-height {
  height: calc(#{$height-footer} - 1px);
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}