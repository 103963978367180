/*Box sizing model fix
----------------------------------------------------------------------------*/
html {
  box-sizing: border-box;
}
*, *::before, *::after { /* Select all elements, as well as the pseudo one (dynamically CSS created) */
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-main;
  color: $col-text;
}

h1, h2, h3, h4, h5, h6, i {
  color: $col-head;
  font-family: $font-highlight;
}

section {
  padding: 5px;
}

h1 {
  margin: 0.4em auto 0.4em;
}

/* Toggle text */
.toggled-text {
  height: 1em;
  overflow: hidden;
  &.toggle {
      height: auto;
  }
}
.readmore-btn {
    display: inline;
}
.readless-txt, .toggled-text.toggle~.readmore-btn span.readmore-txt {
    display: none;
}
.toggled-text.toggle~.readmore-btn span.readless-txt {
    display: block;
}